// encoding: UTF-8
//
// (C) Copyright 2022 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import './3rd-party/fontawesome6/css/fontawesome.min.css';
import './3rd-party/fontawesome6/js/fontawesome.min.js';
import './3rd-party/fontawesome6/css/regular.min.css';
import './3rd-party/fontawesome6/js/regular.min.js';
import './3rd-party/fontawesome6/css/solid.min.css';
import './3rd-party/fontawesome6/js/solid.min.js';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import './css/fonts.css'
import './css/main.css'

import * as Media from './media.js'
import * as MyLightbox from './lightbox.js'
import * as Calendar from './calendar.js'
import * as Photostrip from './photostrip.js'
import * as Kuckuck from './kuckuck.js'

(function kat() {

  document.addEventListener("DOMContentLoaded", () => {

    window.addEventListener('scroll', () => {
      document.querySelectorAll('.calendar, .biography, .photo-strip').forEach(element => {
        var distInView = element.getBoundingClientRect().top - window.innerHeight + 20;
        if (distInView < 0) {
            element.classList.add("visible");
        }
      });
    });

    Calendar.initialize();
    Media.initialize();
    MyLightbox.initialize();
    Photostrip.initialize();
    Kuckuck.initialize();
  });

  // @TODO replace with kuckuck?
  // window.onscroll = () => {
  //   if (document.body.scrollTop > 74 || document.documentElement.scrollTop > 74) {
  //     document.getElementById("kuckuck").style.top = "0";
  //   } else {
  //     document.getElementById("kuckuck").style.top = "-160px"; //"-74px";
  //   }
  // }

})();