// encoding: UTF-8
//
// (C) Copyright 2022 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//

export function initialize() {
  window.onscroll = () => {

    if (document.body.scrollTop > 74 || document.documentElement.scrollTop > 74) {
      document.getElementById("kuckuck").style.top = "0";
    } else {
      document.getElementById("kuckuck").style.top = "-160px"; //"-74px";
    }
  }
}