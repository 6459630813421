// encoding: UTF-8
//
// (C) Copyright 2022 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//


export function initialize() {
  addMediaEntryHandlers();
  addYoutubeHandlers();
  addAudioPlayerHandlers();
}


function addMediaEntryHandlers() {
  Array.from(document.getElementsByClassName("media-entry")).forEach((mediaEntry) => {
    const mediaDetails = mediaEntry?.querySelector('.media-entry-details');
    const mediaChevronDown = mediaEntry?.querySelector('.media-chevron-down');

    if (mediaDetails && mediaChevronDown) {
      //const icon = mediaChevronDown.firstChild;

      mediaEntry.addEventListener("click", function(event) {

        // enable clicks on links
        if (event.target.nodeName == 'A' || event.target.nodeName == 'IMG') return;

        if (mediaDetails.classList.contains('show')) {
          mediaDetails.classList.remove('show');

          var button = event.target.closest("div.media-entry").querySelector("button.media-chevron-down");
          const svg = mediaChevronDown.firstChild;
          button.removeChild(svg);
          button.innerHTML = '<i class="fa-solid fa-chevron-down"></i>';

          // icon.classList.remove("fa-chevron-up");
          // icon.classList.add("fa-chevron-down");
        } else {
          mediaDetails.classList.add('show');

          var button = event.target.closest("div.media-entry").querySelector("button.media-chevron-down");
          const svg = mediaChevronDown.firstChild;
          button.removeChild(svg);
          button.innerHTML = '<i class="fa-solid fa-chevron-up"></i>';
          // icon.classList.remove("fa-chevron-down");          
          // icon.classList.add("fa-chevron-up");          
        }
      });
    }
  });
}


function addYoutubeHandlers() {
  document.querySelectorAll(".youtube").forEach(element => {

    element.querySelector(".play").addEventListener("click", event => {
      event.preventDefault();

      let mediaURL = '';
      //const url = new URL(element.getAttribute("data-id"));
      const url = new URL(element.dataset.id);
      if (url?.href.indexOf('youtu') > 0) {
        //const v = url.searchParams.get('v');
        const t = url.searchParams.get('t');
        const p = url.pathname;
        mediaURL = "https://www.youtube-nocookie.com/embed" + p + '?start=' + t;
      }

      const style = getComputedStyle(element);
      const width  = element.offsetWidth  + parseInt(style.marginLeft) + parseInt(style.marginRight);
      const height = element.offsetHeight + parseInt(style.marginTop)  + parseInt(style.marginBottom);

      if (element.dataset.embed == 'true') {
        const iframe = document.createElement("iframe");
        // https://stackoverflow.com/questions/21607808/convert-a-youtube-video-url-to-embed-code
        //iframe.setAttribute("src", "https://www.youtube-nocookie.com/embed/" + element.getAttribute("data-id")  + (element.getAttribute("data-id").indexOf('?') > -1 ? "&" : "?") + "autoplay=1");
        //iframe.setAttribute("src", "https://www.youtube-nocookie.com/embed/" + element.getAttribute("data-id"));//  + (element.getAttribute("data-id").indexOf('?') > -1 ? "&" : "?") + "autoplay=1");
        //iframe.setAttribute("src", element.getAttribute("data-id"));
        iframe.setAttribute("src", mediaURL);
        iframe.setAttribute("allow", "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen");
        iframe.setAttribute("class", "");
        iframe.setAttribute("style", "display: block /* removes the extra space around the iframe */; border: 0; padding: 0; margin: 0; top: 0; left: 0; width: " + width + "px; height: " + height + "px;");
        element.parentNode?.replaceChild(iframe, element);
      } else {
        open(url, '_blank', 'noopener noreferrer');
      }
    });
  });
}


function addAudioPlayerHandlers() {
  document.querySelectorAll(".audio-player").forEach(element => {
    element.querySelector(".play").addEventListener("click", event => {
      event.preventDefault();

      const style = getComputedStyle(element);
      const width  = element.offsetWidth  + parseInt(style.marginLeft) + parseInt(style.marginRight);
      const height = element.offsetHeight + parseInt(style.marginTop)  + parseInt(style.marginBottom);
      const iframe = document.createElement("iframe");
      iframe.setAttribute("src", element.getAttribute("data-id"));
      iframe.setAttribute("allow", "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen");
      iframe.setAttribute("class", "");
      iframe.setAttribute("style", "display: block /* removes the extra space around the iframe */; border: 0; padding: 0; margin: 0; top: 0; left: 0; width: " + width + "px; height: " + height + "px;");
      element.parentNode?.replaceChild(iframe, element);
    });
  });
}

