// encoding: UTF-8
//
// (C) Copyright 2022 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//

const imagesLoaded = require('imagesloaded')

export function initialize() {

  const photoStripContainer = document.querySelector('.photo-strip-container');
  if (! photoStripContainer) return;


  // KAT Photo Strip
 (() => {

    const do_layout = (startPos = 0, animate = true, placeButtons = false) => {

      const items = document.querySelectorAll('.photo-strip .strip-photo');
      let pos = startPos;

      for (let i = 0; i < items.length; i++) {
        if (! animate) {
          items[i].classList.add('notransition');
        }
        let img = items[i].querySelector('img'); // optimize
        items[i].style.transform = "translateX(" + pos + "px)";// "matrix(1, 0, 0, 1, " + pos + ", 0)";
        pos += img.clientWidth;
        if (! animate) {
          items[i].offsetHeight; // Trigger a reflow, flushing the CSS changes
          items[i].classList.remove('notransition');
        }
      }

      const photoStrip = items[0].closest('.photo-strip');
      const photoStripContainer = photoStrip.closest(".photo-strip-container");

      // hide buttons if all photos fit on the screen
      const navButtons = document.querySelector(".photo-strip-nav-buttons");
      if (pos + Math.abs(startPos) <= window.innerWidth) {
        navButtons.style.visibility = 'hidden';
      } else {
        navButtons.style.visibility = 'visible';
      }

      if (placeButtons) {
        if (pos > photoStripContainer.clientWidth) { // @TODO user container instead
          photoStrip.style.width = photoStripContainer?.clientWidth + "px";
        } else {
          photoStrip.style.width = pos + "px";
        }
      }
      return pos;
    };


    let touchstartX = 0
    let touchendX = 0

    window.onresize = function reportWindowSize() {
      do_layout(0, false, true);
    }

    const photoStrip = document.querySelector('.photo-strip');
    if (photoStrip) {
      imagesLoaded(photoStrip, () => {
        do_layout(0, false, true);
      });
    }

    /* BEGIN HANDLE TOUCH */
    photoStripContainer.addEventListener('touchstart', e => {
      touchstartX = e.changedTouches[0].screenX;
    });

    // @TODO disable if all photos fit on screen?
    photoStripContainer.addEventListener('touchend', e => {
      touchendX = e.changedTouches[0].screenX
      if (touchendX < touchstartX) movePhotoStripRight(e);
      if (touchendX > touchstartX) movePhotoStripLeft(e)
    });
    /* END HANDLE TOUCH */

    const movePhotoStripLeft = event => {
      const button = event.srcElement.closest(".btn.btn-prev");
      if (button)  button.disabled = true;

      let items = document.querySelectorAll('.photo-strip .strip-photo');
      const newFirst = items[items.length - 1].cloneNode(true);

      newFirst.id = "slide-0000";
      newFirst.classList.add('notransition');
      newFirst.style.transform = "translateX(-" + items[items.length - 1].clientWidth + "px)";
      photoStrip.prepend(newFirst);
      newFirst.offsetHeight; // Trigger a reflow, flushing the CSS changes
      newFirst.classList.remove('notransition');
      newFirst.addEventListener("transitionend", function habeFertig(event) {
        items[items.length - 1].remove();
        event.srcElement.removeEventListener("transitionend", habeFertig);
        if (button) {
          setTimeout( () => {
            button.disabled = false;
          }, 100);
        }
      });

      do_layout(0, true);

      newFirst.id = items[items.length - 1].id;
    };
    document.querySelector(".btn-prev").addEventListener("click", movePhotoStripLeft);


    const movePhotoStripRight = event => {
      const button = event.srcElement.closest(".btn.btn-next");
      if (button) button.disabled = true;

      let items = document.querySelectorAll('.photo-strip .strip-photo');
      const last = items[items.length - 1];
      const matrix = new WebKitCSSMatrix(window.getComputedStyle(last).transform);

      const newLast = items[0].cloneNode(true);
      newLast.id = "slide-9999";
      newLast.classList.add('notransition');

      newLast.style.transform = "translateX(" + (matrix.m41 + last.clientWidth) + "px)";
      photoStrip.appendChild(newLast);
      newLast.offsetHeight; // Trigger a reflow, flushing the CSS changes
      newLast.classList.remove('notransition');

      let pos = do_layout(-items[0].clientWidth, true);

      items[0].addEventListener("transitionend", function habeFertig(event) {
        newLast.id = event.srcElement.id;
        event.srcElement.remove();
        event.srcElement.removeEventListener("transitionend", habeFertig);

        if (button) {
          setTimeout( () => {
            button.disabled = false;
          }, 100);
        }
      });
    };
    document.querySelector(".btn-next").addEventListener("click", movePhotoStripRight);
  })();

}
