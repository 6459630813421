// encoding: UTF-8
//
// (C) Copyright 2022 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//

// https://trvswgnr.github.io/bs5-lightbox/
import Lightbox from 'bs5-lightbox'
//import { Masonry } from 'masonry-layout'

const Masonry      = require('masonry-layout')
const imagesLoaded = require('imagesloaded')


export function initialize() {

  document.querySelectorAll('.kat-lightbox').forEach((el) => el.addEventListener('click', event => {
    event.preventDefault();
    new Lightbox(el, {keyboard: true, size: 'fullscreen'}).show();
  }));

  var grid = document.querySelector('#masonry-row');
  if (grid) {
    imagesLoaded(grid, () => {
      const msnry = new Masonry( grid, {
        percentPosition: true
      });
      msnry.layout();
    });
  }
}