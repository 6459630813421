// encoding: UTF-8
//
// (C) Copyright 2022 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//

export function initialize() {

  Array.from(document.getElementsByClassName("calendar-entry")).forEach((calendarEntry) => {

    const eventDetails = calendarEntry?.querySelector('.event-details');
    const collapsible = calendarEntry?.querySelector('.collapsible');

    if (eventDetails && collapsible) {
      const icon = collapsible.firstChild;

      calendarEntry.addEventListener("click", function(event) {

        // enable clicks on links
        if (event.target.nodeName == 'A') return;

        if (eventDetails.classList.contains('show')) {
          eventDetails.classList.remove("show", "mt-3");
          eventDetails.classList.add("mt-0");
          icon.classList.add("fa-chevron-down");
          icon.classList.remove("fa-chevron-up");
        } else {
          eventDetails.classList.remove("mt-0");
          eventDetails.classList.add("show", "mt-3");
          icon.classList.remove("fa-chevron-down");
          icon.classList.add("fa-chevron-up");
        }
      });
    }

  });
}